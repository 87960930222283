import React from "react";
import st from "./signIn.module.scss";
import { META_TITLE, CANONICAL_URL_BASE } from "constants/meta";
import Head from "next/head";
import { endpoints } from "config";
import { PageCard } from "components/Containers/PageCard";
import { Button } from "components/Button";
import { Disclaimer } from "components/Forms/Disclaimer";
import { useRouter } from "next/router";

export default function SignIn(): JSX.Element {
  const router = useRouter();
  return (
    <section className={st.wrapper}>
      <Head>
        <title>Login to {META_TITLE}</title>
        <link rel="canonical" href={`${CANONICAL_URL_BASE}/sign-in`} />
      </Head>

      <PageCard title="PepDirect Admin" cardTitle="Account Login">
        <div className={st.signIn}>
          <Button
            type="primary"
            block
            onClick={() => {
              router.push(`${endpoints.adminApi}/sso/signin?return_to=${endpoints.web}`);
            }}
          >
            PepsiCo SSO
          </Button>
          <Disclaimer action="Login" />
        </div>
      </PageCard>
    </section>
  );
}
