import React, { ReactNode } from "react";

import st from "./pageCard.module.scss";

export function PageCard({
  children,
  title,
  cardTitle,
}: {
  children: ReactNode | string;
  title: string;
  cardTitle: string;
}): JSX.Element {
  return (
    <div className={st.pageCard}>
      <div className={st.container}>
        <h1 className={st.header}>{title}</h1>
        <div className={st.box}>
          <h2 className={st.headerSmall}>{cardTitle}</h2>
          <div className={st.boxInner}>{children}</div>
        </div>
      </div>
    </div>
  );
}
