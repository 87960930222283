import React from "react";
import st from "./disclaimer.module.scss";

export function Disclaimer({ action }: { action: string }): JSX.Element {
  return (
    <div className={st.text}>
      By clicking &lsquo;{action}&lsquo;, you are indicating that you have read and agreed to the{" "}
      <a href="https://www.pepsico.com/legal/privacy" target="_blank" rel="noopener noreferrer">
        Terms of Service
      </a>{" "}
      and{" "}
      <a
        href="https://www.pepsico.com/legal/terms-of-use"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      .
    </div>
  );
}
